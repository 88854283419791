import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="288"
      height="288"
      viewBox="0 0 76.2 76.2"
    >
      <defs>
        <filter id="lightSaber" x="0" y="0">
          <feGaussianBlur
            in="offOut"
            result="blurOut"
            stdDeviation="0.4"
          ></feGaussianBlur>
        </filter>
      </defs>
      <path
        fill="#DD9BAB"
        stroke="#6A656F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M50.21 21.731c1.366-.143 3.727-.197 7.083-.16 2.376 0 3.876.026 4.5.08.624.053 1.336.187 2.138.402 1.99.501 3.683 1.18 5.08 2.04 1.365.822 2.353 1.6 2.962 2.334.609.733 1.032 1.636 1.27 2.71.118.643.185 1.475.2 2.495.015 1.02-.052 1.797-.2 2.334-.476 1.896-1.426 3.622-2.852 5.178-1.425 1.556-3.415 3.032-5.97 4.427-1.722.966-3.727 1.824-6.014 2.576-2.287.751-4.292 1.216-6.014 1.395-.713.072-1.07.215-1.07.43 0 .25-.282 1.34-.846 3.273-.565 1.931-.995 3.237-1.292 3.917-.09.215-.275.43-.557.644a3.586 3.586 0 01-.824.483c-.505.143-1.003.035-1.493-.322-.49-.358-.81-.877-.958-1.556a3.07 3.07 0 01-.067-1.315c.075-.412.305-1.243.691-2.495a66.506 66.506 0 001.114-3.703c.564-2.576 1.336-5.295 2.317-8.156 1.128-3.327 1.841-5.51 2.138-6.547.356-1.503.817-2.433 1.381-2.79.743-.501 1.47-.528 2.183-.08.713.446 1.07 1.153 1.07 2.119 0 .822-.624 3.058-1.872 6.707-.89 2.648-1.321 3.99-1.292 4.025.06.071.824-.099 2.295-.51 1.47-.411 2.606-.778 3.408-1.1 3.623-1.395 6.37-3.023 8.242-4.883a11.833 11.833 0 001.827-2.361c.237-.43.356-1.038.356-1.825V30.37l-.668-.59c-3-2.611-8.54-3.56-16.618-2.844-4.99.43-7.826.519-8.51.269a2.435 2.435 0 01-.89-.698c-.179-.25-.29-.465-.335-.644-.044-.179-.067-.555-.067-1.127s.023-.948.067-1.127c.045-.179.156-.375.335-.59.267-.358.594-.59.98-.698.386-.107 1.203-.196 2.45-.268a84.995 84.995 0 004.322-.322z"
      ></path>
      <path
        fill="#6A656F"
        stroke="#DD9BAB"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M-16.081-22.274c1.23 0 2.402-.111 3.514-.334 1.112-.224 2.082-.662 2.91-1.317.829-.655 1.485-1.585 1.97-2.79.486-1.205.728-2.79.728-4.753 0-3.035-.58-5.304-1.739-6.806-1.159-1.503-2.969-2.284-5.43-2.344l1.492-4.014h10.401l-.07-9.5H-17.29l-5.825 11.153h-.104v-11.153h-9.76v31.858h16.897zm-4.082-14.954c.473 0 .923.037 1.35.112a2.33 2.33 0 011.1.491c.308.253.551.618.729 1.095.177.476.266 1.101.266 1.876 0 1.042-.219 1.809-.657 2.3-.438.492-1.202.737-2.291.737h-3.552v-6.61h3.055z"
        transform="rotate(180)"
      ></path>
      <g strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="0.1">
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M38.599 75.275c.137-.018.152-.03.152-.114v-.093l-.295.024c-.162.013-.583.02-.936.017l-.64-.008v.08c0 .078.005.08.335.09.666.021 1.234.022 1.384.004zM38.507 74.96c.194-.017.265-.036.265-.07 0-.039-.053-.044-.305-.031-.447.022-1 .019-1.313-.01-.26-.022-.275-.02-.275.04 0 .038.037.075.086.09.102.028 1.162.016 1.542-.019zM38.616 74.7c.41-.043.467-.067.325-.133l-.22-.103c-.18-.085-.036-.082.197.004.118.043.22.08.228.08.023 0 .013-2.237-.011-2.285-.044-.087-.652-.219-1.014-.22l-.326-.003.007.14c.028.603.015 1.591-.022 1.64-.024.032-.045.05-.046.042a29.62 29.62 0 01-.026-.498 67.987 67.987 0 00-.05-.903l-.026-.42-.153-.01c-.209-.013-.773.078-1.039.168l-.217.073.028 1.099c.016.604.033 1.102.039 1.107.005.005.121-.05.258-.122s.259-.124.27-.115c.022.017-.196.187-.386.302-.056.034-.087.072-.07.086.105.08 1.695.13 2.254.071z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.706 71.97c.267-.082.3-.084 1.048-.084.699 0 .797.007 1.014.07.132.038.247.069.254.069.008 0 .014-1.779.014-3.952V64.12l-.132-.06c-.267-.124-.437-.147-1.068-.147-.557 0-.64.008-.941.086l-.331.085-.02.475c-.01.261-.006.487.011.5.016.015.183-.011.37-.058.405-.1 1.027-.14 1.146-.07.042.024.081.1.095.186.03.185-.069.275-.301.275-.325 0-.936.166-1.218.33-.117.068-.134.096-.134.22 0 .16-.035.156.387.04.188-.052.343-.069.677-.071.516-.004.564.015.563.224 0 .236-.03.285-.177.285-.313 0-1.032.136-1.338.254-.147.056-.152.064-.152.224 0 .18.026.224.104.174.114-.072.619-.158.958-.163.463-.006.566.04.565.251 0 .08-.016.165-.034.187-.018.022-.178.061-.355.087-.531.079-.984.174-1.115.235-.12.056-.123.065-.123.292 0 .183.012.234.058.234.032 0 .07-.013.082-.03.054-.067.55-.158.969-.177.388-.019.446-.014.518.035.102.071.115.329.02.402-.08.061-.32.112-.53.112-.225 0-.557.07-.885.186l-.273.097v.204c0 .183.007.202.071.182.446-.14.902-.204 1.272-.179.276.02.355.07.39.247.045.234-.032.265-.876.35a2.655 2.655 0 00-.6.126l-.257.092v.19c0 .106.014.187.03.18a3.74 3.74 0 011.208-.242c.313 0 .471.134.471.4 0 .202-.04.216-.61.229-.535.012-.572.02-.965.207-.17.081-.172.085-.173.261-.001.099.013.18.03.18.018 0 .136-.029.263-.063.352-.094.959-.145 1.166-.098.207.047.305.171.265.334-.033.136-.123.18-.464.231-.565.084-1.21.246-1.268.32-.027.033-.01.085.028.085.01 0 .142-.038.293-.085z"
          ></path>
          <path
            fill="#AAA"
            stroke="red"
            d="M36.813 71.682c.198-.05.473-.108.61-.127.502-.069.493-.066.507-.19.011-.104.001-.115-.13-.148-.241-.061-1.314.09-1.418.2-.053.056-.037.359.02.358.027 0 .213-.042.41-.093zM36.55 70.674c.12-.111.466-.186.94-.204l.468-.018.012-.11c.018-.162-.104-.248-.35-.247-.345.002-1.017.153-1.157.26-.08.062-.094.362-.017.362a.18.18 0 00.104-.043zM36.9 69.57c.157-.035.438-.071.626-.082.44-.024.501-.05.485-.198-.012-.113-.015-.115-.236-.142-.258-.032-.69.013-1.089.113l-.254.063v.2c0 .198 0 .198.091.153.05-.025.22-.073.377-.107zM36.667 68.604c.262-.1.546-.159.914-.187.38-.03.479-.065.478-.172-.001-.134-.06-.16-.35-.151-.418.012-.725.058-.99.149-.264.09-.268.094-.283.286-.012.151.013.159.231.075zM36.593 67.542c.108-.045.8-.178 1.06-.204.27-.027.325-.057.325-.176 0-.07-.025-.098-.112-.123-.174-.053-.7-.013-1.066.08l-.327.084v.185c0 .195.004.2.12.154zM37.083 66.426c.19-.036.437-.074.55-.084.39-.037.386-.035.386-.176v-.128l-.499.014c-.405.012-.544.029-.742.09-.23.071-.246.083-.28.206-.02.072-.025.153-.011.18.022.044.038.044.137.006.062-.024.269-.072.459-.108zM37.18 65.362a3.49 3.49 0 01.452-.08c.376-.034.387-.038.387-.144v-.097l-.438.018c-.449.018-.973.112-1.034.185-.038.045-.045.316-.01.343.013.01.108-.022.21-.072.101-.05.297-.118.433-.153z"
          ></path>
          <path
            fill="#AAA"
            stroke="#00f"
            d="M36.895 63.846c.268-.076.338-.082.94-.083.601 0 .673.005.927.079l.276.08-.011-.892-.011-.892-.244-.092c-.225-.084-.288-.093-.78-.105-.79-.019-1.334.07-1.375.224-.03.118-.068 1.764-.04 1.764.016 0 .159-.038.318-.083z"
          ></path>
          <path
            fill="#AAA"
            stroke="red"
            d="M36.556 62.011c.177-.163.752-.249 1.503-.223.464.015.803.08 1.045.2.129.065.156.01.147-.308-.009-.299-.076-.462-.221-.535-.158-.079-.778-.144-1.372-.143-.843 0-1.154.087-1.29.357-.073.147-.06.411.03.607.047.106.082.116.158.045z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.778 60.913c.314-.094 1.733-.08 2.093.019.052.014.05.006-.009-.045-.04-.035-.219-.116-.398-.18-.379-.137-.718-.172-1.06-.11-.287.05-.931.303-.931.364 0 .033.021.04.07.024l.235-.072z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.86 60.638c.273-.117.645-.175 1.036-.16.322.011.376.024.708.159.198.08.376.146.396.146.053 0 .044-.055-.015-.091a10.28 10.28 0 01-.234-.16c-.236-.164-.332-.201-.642-.248-.368-.056-.669-.025-1.081.11-.453.15-.555.21-.555.333v.095l.112-.057c.061-.03.185-.088.276-.127z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M39.036 60.47c0-.146-.013-.167-.198-.308-.5-.383-1.156-.438-1.907-.159-.365.136-.439.191-.47.352-.028.137 0 .143.206.045.369-.176.824-.3 1.1-.301.222 0 .669.097.84.184.09.044.218.14.286.211.068.071.128.13.133.132a.59.59 0 00.01-.155z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.689 59.975c.39-.202.904-.306 1.325-.269.27.024.675.162.819.278.14.114.203.106.203-.027 0-.162-.252-.36-.58-.454-.206-.06-.33-.073-.66-.072-.366 0-.44.011-.709.1a3.734 3.734 0 00-.457.181c-.142.075-.157.097-.157.22 0 .074.008.135.018.135.01 0 .099-.042.198-.092z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.806 59.508c.164-.068.417-.147.563-.175.486-.096 1.142-.012 1.492.192.084.049.14.063.157.042.05-.063-.024-.3-.116-.365-.244-.172-1.075-.318-1.564-.275-.294.027-.66.137-.794.24-.09.068-.112.114-.112.226 0 .127.029.238.062.238.007 0 .147-.055.312-.123z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M37.08 58.84c.433-.097 1.163-.038 1.635.133.147.054.28.098.295.098.014 0 .026-.078.026-.173 0-.215-.082-.287-.5-.434-.554-.195-1.239-.22-1.69-.062-.375.131-.414.173-.414.443v.23l.214-.093a2.93 2.93 0 01.434-.142z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M39.036 58.273c0-.195-.008-.213-.137-.306-.193-.14-.567-.236-.993-.257-.416-.02-.746.021-1.063.132-.354.124-.411.179-.411.393 0 .1.018.182.04.182.022 0 .128-.042.237-.093.275-.13.558-.169 1.04-.145.398.02.86.127 1.096.254.162.086.191.061.191-.16z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.727 57.762c.564-.234 1.468-.24 2.038-.015.109.043.214.078.234.078.02 0 .037-.057.037-.126 0-.255-.391-.401-1.14-.426-.526-.018-.835.025-1.22.171l-.224.085-.012.164c-.007.09.004.163.023.163.02 0 .139-.042.264-.094z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M37.125 57.198c.55-.106 1.409-.05 1.737.115.13.065.174.034.174-.123 0-.226-.165-.29-.98-.376-.471-.05-.535-.05-.819-.006-.17.027-.415.082-.546.122l-.239.073-.012.15c-.02.228.001.248.186.174.086-.034.31-.092.499-.129z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M37.295 56.672c.252-.03.389-.028.824.015.287.028.585.071.663.096.226.072.254.062.254-.091 0-.282-.254-.364-1.19-.385-.572-.013-.67-.007-.935.054-.443.102-.48.126-.48.323 0 .091.012.174.025.184.013.01.14-.022.28-.07.141-.049.393-.105.56-.126z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.88 56.248c.282-.078.35-.084.915-.082.504.001.657.013.875.067.367.09.366.09.366-.089 0-.207-.068-.237-.675-.3-.554-.057-1.158-.042-1.542.037-.383.08-.387.082-.387.298 0 .167.008.188.071.172.04-.01.209-.056.376-.103z"
          ></path>
          <path
            fill="#7F7F7F"
            stroke="red"
            d="M36.717 55.796c.368-.112 1.562-.115 2.207-.007l.112.02v-.441l-.152-.025c-.266-.042-2-.02-2.228.03l-.204.043-.012.223c-.006.124.003.224.02.224.018 0 .134-.03.257-.067z"
          ></path>
          <path
            fill="#AAA"
            stroke="red"
            d="M36.166 55.353c.419-.112.637-.146 1.154-.179.76-.048 1.66 0 2.054.113l.276.078.1-.163a.69.69 0 00.1-.28c0-.138-.07-.174-.534-.273a8.028 8.028 0 00-2.445-.106c-.342.04-.495.078-.836.204-.39.146-.417.162-.417.249 0 .11.055.199.196.315.12.098.133.1.352.042z"
          ></path>
        </g>
        <path
          fill="#7eb0fc"
          stroke="#1f56ad"
          strokeWidth="0.25"
          d="M36.335 54.507c.304-.146 1.67-.198 2.437-.093l.524.072.117.015-.026-9.322a2901.87 2901.87 0 00-.045-11.223c-.011-1.044-.03-7.245-.041-13.779-.024-14.045-.048-18.106-.11-18.446-.04-.225-.059-.259-.237-.423a.995.995 0 00-.656-.298c-.22-.025-.574.042-.734.138-.127.076-.335.373-.443.63-.09.219-.101.522-.2 5.912-.023 1.31-.061 3.272-.084 4.36-.023 1.087-.06 3.09-.08 4.453a976.5 976.5 0 01-.08 4.328c-.022 1.019-.058 2.96-.08 4.313a442.36 442.36 0 01-.063 3.285c-.013.454-.041 1.953-.063 3.332a906.17 906.17 0 01-.08 4.36c-.022 1.019-.058 2.931-.08 4.25s-.059 3.337-.081 4.484a3144.77 3144.77 0 00-.103 5.574c-.033 1.919-.068 3.63-.077 3.806-.009.174-.006.325.007.335.03.022.166-.009.278-.063z"
          filter="url(#lightSaber)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
